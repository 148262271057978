import React, {useState} from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import Secu from "../../assets/images/security.png";
import {GrClose} from "react-icons/gr";
import {useNavigate} from "react-router-dom";
import {removeToken} from "../../services/dataGuetter/dataFetcher";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import {useSaveOptIn} from "../../features/user/user.hooks";

interface ModalOptInCtrlProps{
    className?:string;
    user:MeMob
}

const ModalOptInCtrl = ({className, user}:ModalOptInCtrlProps)=>{
    const navigate = useNavigate();
    const [acceptCGU, setAcceptCGU] = useState(false);
    const [acceptPo, setAcceptPo] = useState(false);
    const [visible, setVisible] = useState(true);
    const mutation = useSaveOptIn();
    const CloseMe = ()=>{
        removeToken();
        navigate('/login')
    }
    const SwitchCGU = ()=>{
        setAcceptCGU(_o=>!_o)
    }
    const SwitchPol = ()=>{
        setAcceptPo(_o=>!_o)
    }
    const SaveOpt = ()=>{
        mutation.mutate(user.id);
        setVisible(false)
    }
    return ReactDOM.createPortal(
        <div className={`ModalOptin ${className} ${visible ? 'visible' : 'invisible'}`}>
            <div className={`modal-in`}>
                <div className={`close-part`} onClick={CloseMe}>
                    <div className={"closeMe"}><GrClose/></div>
                </div>
                <div className={`content`}>
                    <div className={`ill`}>
                        <img src={Secu} alt={"Illustration sécurité"}/>
                        <div className={"under-img"}>Avant de continuer</div>
                    </div>
                    <div className={`text-place`}>
                        <p>Bienvenue sur Timed,</p>
                        <p>Avant de continuer, veuillez lire attentivement nos Conditions Générales d'Utilisation (CGU) ainsi que notre politique de confidentialité ci-dessous.</p>
                        <p>Veuillez noter que l'utilisation de ce service est soumise à l'acceptation des CGU et de la politique de confidentialité. Si vous n'êtes pas d'accord avec ces conditions, vous ne pourrez pas utiliser ce service.</p>
                        <p>Ces documents définissent les règles que nous devons tous respecter pour garantir un fonctionnement optimal de notre service, ainsi que la manière dont nous gérons et protégeons vos données personnelles.</p>
                        <p>En cochant les cases "Accepter" ci-dessous, vous confirmez que vous avez lu et compris les CGU, ainsi que la politique de confidentialité de Timed, et que vous acceptez de les respecter.</p>
                        <p>Si vous avez des questions ou des préoccupations concernant ces documents, n'hésitez pas à nous contacter.</p>
                    </div>
                    <div className={`coche-place`}>
                        <div className={`one-coche`}>
                            <label>
                                <input type={"checkbox"} checked={acceptCGU} onChange={SwitchCGU}/>
                                <span>J’accepte les <a href={"https://www.timed.fr/conditions-generales/"}>conditions générales d’utilisation</a></span>
                            </label>
                        </div>
                        <div className={`one-coche`}>
                            <label>
                                <input type={"checkbox"} checked={acceptPo} onChange={SwitchPol}/>
                                <span>J’accepte la <a href={"https://www.timed.fr/politique-de-confidentialite/"}>politique de confidentialité</a>, incluant la gestion des cookies et la politique RGPD</span>
                            </label>
                        </div>
                    </div>
                    <div className={`submit-place`}>
                        <TimedButton
                            size={"sm"}
                            text={"Continuer"}
                            themeColor={"Primary"}
                            disabled={!acceptPo || !acceptCGU}
                            isPending={mutation.isLoading}
                            onClick={SaveOpt}
                        />
                    </div>
                </div>
            </div>
        </div>
        , document.body
    )
}

export const ModalOptIn = styled(ModalOptInCtrl)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0,0,0,.6);
  &.invisible{
    display: none;
  }
  .submit-place{
    margin: 25px auto 50px auto;
    display: flex;
    justify-content: center;
  }
  .one-coche{
    margin-bottom: 8px;
    label{
      &:hover{
        cursor: pointer;
      }
    }
    span{
      margin-left: 10px;
    }
  }
  .text-place, .coche-place{
    width: 92%;
    margin: 8px auto;
  }
  .text-place{
    p{
      margin-bottom: 10px;
    }
  }
  .ill{
    padding: 1rem;
    text-align: center;
    img{
      width: 180px;
    }
    .under-img{
      font-size: 24px;
      font-weight: bold;
      padding: 0.5rem;
    }
  }
  .modal-in{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: white;
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
  }
  .close-part{
    padding: 0.75rem;
    display: flex;
    justify-content: flex-end;
    &:hover{
      cursor: pointer;
    }
  }
`