import React, {lazy, Suspense} from "react";
import styled from "styled-components";
import {Routes, Route} from "react-router-dom";
import TimedSuspense from "../../components/organisms/layouts/TimedSuspense";

const NotFound = lazy(()=>import("./NotFound"))
const Messagerie = lazy(()=>import("../Messagerie/Messagerie"))
const Conversation = lazy(()=>import("../Messagerie/Conversation"))
const SettingConversation = lazy(()=>import("../Messagerie/SettingConversation"))
const NewConversationMain = lazy(()=>import("../Messagerie/NewConversationMain"))


interface MessagerieRooterCtrlProps{
    className?:string;
}



const MessagerieRooterCtrl = ({className}:MessagerieRooterCtrlProps)=>{
    return (
        <div className={`Dashy ${className}`}>
            <Suspense fallback={<TimedSuspense/>}>
                <Routes>
                    <Route path="" element={<Messagerie/>}/>
                    <Route path="/:id" element={<Conversation/>}/>
                    <Route path="/setting/:id" element={<SettingConversation/>}/>
                    <Route path="/new" element={<NewConversationMain/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </Suspense>
        </div>
    )
}

const MessagerieRooter = styled(MessagerieRooterCtrl)`
    height: 100%;
`

export default MessagerieRooter;