import React from "react";
import styled from "styled-components";
import PulseLoader from "../TimedSpinners/PulseLoader";

interface TimedButtonCtrlProps{
    /**
     * De quelle taille le bouton doit être ?
     */
    size?: "xs" | "sm" | "md" | "l" | "xl";
    /**
     * Couleur du bouton
     */
    themeColor?:"Primary" | "Secondary" | "Complementary" | "Tertiary" | "Warning" | "Success" | "Neutre";
    /**
     * Bouton Rond ou juste radius
     */
    isRounded?:boolean;
    /**
     * Texte dans le bouton
     */
    text?:string;
    children?: React.ReactNode;
    /**
     * Fonction réagissant au click
     */
    onClick?: Function;
    /**
     * Doit-il être désactivé ou non ?
     */
    disabled?: boolean;
    /**
     * Classe HTML du bouton ?
     */
    className?: string;
    /**
     * En train de travailler ?
     */
    isPending?: boolean;
    type?: "button" | "reset" | "submit";
    isFull?:boolean;
}

const TimedButtonCtrl = ({themeColor, size, disabled, type, text, children, isPending, onClick, isFull, className, isRounded, ...props }:TimedButtonCtrlProps)=>{
    const handleClick = ()=>{
        if(onClick){
            onClick();
        }
    }
    return (
        <button onClick={handleClick} type={type} disabled={isPending ? isPending : disabled} className={`ButtonTimed ${isFull ? 'fully' : ''} ${className}`} {...props}>
            {isPending ? <PulseLoader className={'my-waity'} size={"xs"} themeColor={"Neutre"}/> : children||text  }
        </button>
    )
}

const TimedButton = styled(TimedButtonCtrl)`
  border-radius: ${props => props.isRounded ? "25px" : "0.4rem"};
  font-size: ${props=>props.size === "xs" ? "12px" : props.size==="sm" ? "15px" : props.size==="md" ? "18px" : props.size==="l" ? "20px" : "22px"};
  padding: ${props=>props.size === "xs" ? "0.25rem 0.5rem" : "0.5rem 2rem"};
  border: none;
  //font-variant: small-caps;
  //letter-spacing: 0.10rem;
  font-weight: 400;
  &:hover{
    cursor: pointer;
  }
  &.fully{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: block;
  }
  .my-waity{
    margin-right: 5px;
  }
  background: ${props=>
          props.themeColor === "Primary" ? props.theme.Primary:
          props.themeColor === "Secondary" ? props.theme.Secondary:
          props.themeColor === "Tertiary" ? props.theme.Tertiary : 
                props.themeColor === "Warning" ? props.theme.Warning:
                props.themeColor === "Success" ? props.theme.SuccessLight
                : props.theme.NeutreLight
  };
  color: ${props=>
          (props.themeColor === "Primary" || props.themeColor==="Secondary" || props.themeColor==="Warning" || props.themeColor === "Tertiary" || props.themeColor === "Complementary" ) ? "white": "black"};
  &:disabled{
    background: ${props=>
            props.themeColor === "Primary" ? props.theme.PrimaryExtraLight:
                    props.themeColor === "Secondary" ? props.theme.SecondaryExtraLight:
                            props.themeColor === "Tertiary" ? props.theme.TertiaryExtraLight :
                                    props.themeColor === "Warning" ? props.theme.WarningExtraLight:
                                            props.themeColor === "Success" ? props.theme.SuccessExtraLight
                                    : props.theme.NeutreExtraLight
    };
  }
`

TimedButton.defaultProps={
    themeColor:"Neutre",
    size:"sm",
    isPending:false,
    disabled:false,
    text:"Button",
}

export default TimedButton;