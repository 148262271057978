import React, {useState} from "react";
import styled from "styled-components";

interface TimedInputTextCtrlProps{
    /**
     * class css du compossant
     */
    className?:string;
    /**
     * Intitule du champ
     */
    label:string;
    /**
     * type de données dans le input
     */
    type:"text" | "date" | "datetime" | "number" | "password";
    /**
     * Champ obligatoire ?
     */
    isRequired:boolean;
    /**
     * le champ est-il en erreur ?
     */
    isError:boolean;
    /**
     * Text d'aide si erreur ou complex
     */
    helperText:string;
    /**
     * valeur initial du champ
     */
    value:any;
    /**
     * Fonction de sélection du champ
     */
    setFieldValue?:Function;
    /**
     * identifiant du champ
     */
    id:string;
    /**
     * taille du input
     */
    size?:"small" | "normal";
    /**
     * input full
     */
    isFull?:boolean
}

/**
 * Composant permettant le control des input de type Text / Date / DateTime / password
 *
 */
const TimedInputTextCtrl = ({className, label, type, isRequired, isError, helperText, value, id, isFull, ...props}:TimedInputTextCtrlProps)=>{
    const [isFocus, setIsFocus] = useState(false);
    return (
        <div className={`TimedFormControl ${className} ${isError ? 'onError' : ''} ${(isFocus || value!=="" || type==="date" || type==="datetime") ? 'onFill' : ''} ${isFocus ? 'focused' : ''} ${isFull ? 'isFull' : ''}`}>
            <label>{label}{isRequired ? '*' : ''}</label>
            <div className={`wrapFormControl`}>
                <input type={type} value={value} id={id} onChange={(e)=>{
                    if(props.setFieldValue) {
                        props.setFieldValue(id, e.currentTarget.value)
                    }
                }
                } onFocus={()=>setIsFocus(true)} onBlur={()=>setIsFocus(false)}/>
                <fieldset aria-hidden={true} className={`fieldSetTimed`}>
                    <legend className={`legTimedInputText`}><span>{label}</span></legend>
                </fieldset>
            </div>
            {helperText &&
            <div className={`helper-text ${isError ? 'onError' : ''}`}>{helperText}</div>
            }
        </div>
    )
}

const TimedInputText = styled(TimedInputTextCtrl)`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 8px;
  border: 0;
  vertical-align: top;
  &.isFull{
    width: 100%;
    margin: 0;
  }

  .helper-text {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 3px 14px 0;
    &.onError{
      color: red;
    }
  }

  &.onError {
    label {
      color: red;
    }

    .fieldSetTimed {
      border-color: red;
    }
  }

  &.focused {
    label {
      color: blue;
    }

    .fieldSetTimed {
      border-color: blue;
    }
  }

  &.onFill, &:-webkit-autofill {
    label {
      max-width: calc(133% - 24px);
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(14px, -9px) scale(0.75);
      transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      z-index: 1;
      pointer-events: auto;
      user-select: none;
    }

    .legTimedInputText {
      max-width: 100%;
      transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    }
  }

  label {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    position: absolute;
    left: 0;
    top: 0;
    transform: ${props=>props.size === "small" ?  "translate(14px, 9px) scale(1)" : "translate(14px, 16px) scale(1)"};
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
  }

  .wrapFormControl {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: text;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
  }

  .fieldSetTimed {
    text-align: left;
    position: absolute;
    inset: -5px 0 0;
    margin: 0;
    padding: 0 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0;
    border-color: rgba(0, 0, 0, 0.23);
  }

  .legTimedInputText {
    float: unset;
    overflow: hidden;
    display: block;
    width: auto;
    padding: 0;
    height: 11px;
    font-size: 0.75em;
    visibility: hidden;
    max-width: 0.01px;
    transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    white-space: nowrap;

    span {
      padding-left: 5px;
      padding-right: 5px;
      display: inline-block;
      opacity: 0;
      visibility: visible;
    }
  }

  input {
    font: inherit;
    letter-spacing: inherit;
    color: currentcolor;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: ${props=>props.size === "small" ?  "8.5px 14px" : "16.5px 14px"};
  }
`



export default TimedInputText;