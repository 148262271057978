import React, {useState} from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {login} from "../../../features/user/user.services";
import TimedLink from "../../../components/atoms/TimedLink/TimedLink";
import TimedFullError from "../../../components/atoms/TimedFullError/TimedFullError";
import TimedButton from "../../../components/atoms/TimedButton/TimedButton";

interface LoginFormMobileCtrlProps{
    className?:string;
    setFull:Function;
    location: any;
}

interface Err{
    username:string|null,
    password:string|null
}

const LoginFormMobileCtrl = ({className, setFull, ...props}:LoginFormMobileCtrlProps)=>{
    const navigate = useNavigate();
    const [datas, setDatas] = useState({username:"", password:""})
    const [errors, setErrors] = useState<Err>({username:null, password:null})
    const [errorsConnexion, setErrorsConnexion] = useState("")
    const [onSubmit, setOnSubmit] = useState(false);
    const handleSubmit = (e:any)=>{
        e.preventDefault();
        setErrorsConnexion("");
        setOnSubmit(true);
        let nbE = 0;
        if(datas.username===""){
            const myErrors = {...errors};
            setErrors({...myErrors, username: "obligatoire"})
            nbE++;
        }
        if(datas.password===""){
            const myErrors = {...errors};
            setErrors({...myErrors, password: "obligatoire"})
            nbE++;
        }
        if(nbE===0) {
            login(datas.username, datas.password)
                .then((result: any) => {
                    const params = localStorage.getItem("q");
                    let q = "";
                    if(params!=="" && params!==null){
                        q = params.replace(/['"]+/g, "");
                    }
                    localStorage.setItem("__timed_user_token__", JSON.stringify(result.token).replace(/['"]+/g, ""));
                    localStorage.setItem("__timed_user_refreshtoken__", JSON.stringify(result.refresh_token).replace(/['"]+/g, ""));
                    //Si l'utilisateur a été déconnecté (token expiré) on le redirige vers la page ou il était, sinon on le redirige vers l'accueil
                    setFull(true);
                    setTimeout(()=>{
                        props.location.state ? navigate(props.location.state.from + q) : navigate("/timed_application");
                    }, 2500)

                })
                .catch((error:any) => {
                    setOnSubmit(false);
                    setErrorsConnexion("Association login/mot de passe incorrect");
                    if (error.message !== "401Error") {
                        throw error;
                    }
                });
        }
    }
    const handleSetField = (id:string, value:string)=>{
        setDatas({...datas, [id]:value});
    }
    return (
        <form onSubmit={(e)=>handleSubmit(e)} className={`LoginForm ${className}`}>
            <div className={`lineLog`}>
                <input placeholder={"Identifiant"} className={`${errors.username !== null ? 'is-error' : ''}`} type={"text"} id={"username"} value={datas.username} onChange={(e:any)=>handleSetField("username", e.currentTarget.value)}/>
                {!!errors.username &&
                    <div className={`errorfield`}>{errors.username}</div>
                }
            </div>
            <div className={`lineLog`} style={{marginBottom:0}}>
                <input placeholder={"Mot de passe"} className={`${errors.password !== null ? 'is-error' : ''}`} type={"password"} id={"password"} value={datas.password} onChange={(e:any)=>handleSetField("password", e.currentTarget.value)}/>
                {!!errors.password &&
                    <div className={`errorfield`}>{errors.password}</div>
                }
            </div>
            <div className="mdp-forgot">
                <TimedLink size={"xs"} text={"mot de passe oublié ?"} themeColor={"Primary"} onClick={()=>navigate('/pwd_retrieve')}/>
            </div>
                {errorsConnexion!=="" && <TimedFullError text={errorsConnexion} textAlign={"center"}/>}
            <div className={"submit-wrapper"}>
                <TimedButton
                    themeColor={"Primary"}
                    isFull={true}
                    text={"Connexion"}
                    type={"submit"}
                    size={"l"}
                    isPending={onSubmit}
                />
            </div>
        </form>
    )
}

export const LoginFormMobile = styled(LoginFormMobileCtrl)`
    height: 100%;
  width: 80%;
  margin: auto;
  .submit-wrapper{
    margin: 30px auto 20px auto;
  }
    .lineLog{
      margin-bottom: 20px;
      input{
        width: 100%;
        height: 42px;
        padding: 0.2rem 1rem;
        border: solid 1px ${props => props.theme.NeutreLight};
        border-radius: 4px;
        text-align: left;
        font-size: 20px;
      }
    }
`