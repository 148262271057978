import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

/**
* Permet de se connecter via l'adresse mail et le mot de passe, retourne un objet content le jwt.
* @param username
* @param password
*/
export function login(username: string, password: string) {
    return dataFetcher("login", {
        method: "POST",
        body: {
            username: username,
            password: password,
        },
    });
}

export function saveOptin(id:number):Promise<User>{
    return dataFetcher(`users/${id}`, {
        method:'put',
        body:{optIn:true}
    })
}

export function changePwd(id:number,old:string, pwd:string){
    return dataFetcher("users/"+id+"/change_pwd", {
        method: "POST",
        body: {
            old: old,
            pwd: pwd,
        },
    });
}

export function getInfosUser():Promise<User>{
    return dataFetcher(`users/me`, {
        method:'get',
    })
}

export function getInfosUserMob():Promise<MeMob>{
    return dataFetcher(`users/memob`, {method:'get'})
}

export async function getUsersByCentre(idCentre:number):Promise<User[]>{
    const res:any = await dataFetcher(`users?Centre=${idCentre}`, {method:'get'});
    return res["hydra:member"];
}

export async function getUsersByCentreBorn():Promise<UserBirth[]>{
    const res:any = await dataFetcher(`users/births`, {method:'get'});
    return res["hydra:member"];
}

export function modifyCoord(datas:ModifyUserFD, id:number):Promise<User>{
    return dataFetcher(`users/${id}`, {
        method:'put',
        body:datas
    })
}

export function forgot_pwd(username:string):Promise<any>{
    return dataFetcher('users/forgot_pwd', {
        method:'post',
        body:{username:username}
    })
}

export function trashUserSignature(id:number):Promise<any>{
    return dataFetcher(`user_signatures/${id}`, {method:'DELETE'});
}

export function addUserSignature(datas:PostNewSignature):Promise<UserDocument>{
    const formData = new FormData();
    for (const [key, value] of Object.entries(datas)){
        formData.append(`${key}`, value);
    }
    return dataFetcher<UserDocument>(
        `user_signatures/postOne`,
        {
            method: "POST",
            body: formData,
        },
        true
    );
}

