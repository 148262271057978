import React from "react";
import styled from "styled-components";
import LoginForm from "./LoginForm/LoginForm";
import LeftLogin from "../../assets/images/Left_login.png";
import {useLocation} from "react-router-dom";
import {myBoxShadow} from "../../assets/styles/elements";

interface LoginDesktopCtrlProps{
    className?:string;
}

const LoginDesktopCtrl = ({className}:LoginDesktopCtrlProps)=>{
    const location = useLocation();
    return (
        <div className={`LoginPage ${className}`}>
            <div className={`wrapConnexion`}>
                <div className="contentWrapConnexion">
                    <div className="ill-side">
                        <img src={LeftLogin} alt={"Welcome to Timed"}/>
                    </div>
                    <div className="connexion-side">
                        <div className="connexion">
                            <h1>Bienvenue !</h1>
                            <LoginForm location={location}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const LoginDesktop = styled(LoginDesktopCtrl)`
  height: 100vh;
  position: relative;
  .wrapConnexion{
    position: absolute;
    width: 75%;
    max-width: 1200px;
    &:after{
      content: "";
      display: block;
      padding-bottom: 50%;
    }
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    ${myBoxShadow};
    .contentWrapConnexion{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: start;
      background: white;
      .ill-side{
        width: 50%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .connexion-side{
        flex-grow: 1;
        position: relative;
        .connexion{
          position: absolute;
          width: 70%;
          left: 50%;
          top:50%;
          transform: translate(-50%,-50%);
          h1{
            margin-bottom: 15px;
          }
        }
      }
    }
  }
`

export default LoginDesktop;