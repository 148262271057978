import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export function newMessage(datas:MessageFormDate):Promise<Message>{
    return dataFetcher(`messages`, {
        method:'post',
        body:datas
    })
}

export async function getByConv(id:number|undefined):Promise<Message[]>{
    const res:any = await dataFetcher(`messages?Conversation=${id}`, {method:'get'})
    return res["hydra:member"];
}

export interface ReturnMessagePage{
    result:Message[],
    nextPage:number,
    first:number,
    last:number
}

export async function getMessagePages(page:string, params:any|null):Promise<ReturnMessagePage>{
    let TabSearch:string[] = [];
    if(params) {
        for (const [key, value] of Object.entries(params)){
            TabSearch.push(`${key}=${value}`)
        }
    }
    const search = TabSearch.length>0 ? `&${TabSearch.join('&')}` : ''
    const res:any = await dataFetcher(`${page}${search}`, {method:'get'})
    const result = res["hydra:member"];
    const TabView = res["hydra:view"];

    const nextPage =  "hydra:next" in TabView ? TabView["hydra:next"].replace("/api/", "") : undefined;
    const first = res["hydra:view"]["hydra:last"];
    const last = res["hydra:view"]["hydra:first"];

    return {result, nextPage, first, last}
}

export async function getMessageNews():Promise<Message[]>{
    const res:any = await dataFetcher(`messages/getNews`, {method:'get'})
    return res["hydra:member"];
}