import React from "react"
import styled, {useTheme} from "styled-components";
import fond from "../../../assets/images/fond_1.png";
import logo from "../../../assets/images/logo-black.png";
import hom from "../../../assets/images/homme-mobile.png";
import ReactDOM from "react-dom";
import {MutatingDots} from "react-loader-spinner";

interface TimedSuspenseCtrlProps{
    className?:string;
}

const TimedSuspenseCtrl = ({className}:TimedSuspenseCtrlProps)=>{
    const theme = useTheme();
    return ReactDOM.createPortal(
        <div className={`TimedSuspense ${className}`}>
            <div className={"content-fond"}>
                {/*<img src={fond} alt={"fond"}/>*/}
            </div>
            <div className={`content-front`}>
                <div className={`logo-place`}>
                    <img src={logo} alt={"Timed application"}/>
                </div>
                <div className={`homme-place`}>
                    <div className={`wrap-spin`}>
                        <MutatingDots
                            height="100"
                            width="100"
                            color={theme.Primary}
                            secondaryColor={theme.Primary}
                            radius='12.5'
                            ariaLabel="mutating-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                    <img src={hom} alt={"Timed mobile"}/>
                </div>
            </div>
        </div>,document.body
    )
}

const TimedSuspense = styled(TimedSuspenseCtrl)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 106%;
  overflow: hidden;
  .wrap-spin{
    width: max-content;
    margin: auto;
  }
  .logo-place{
    text-align: center;
    padding: 1rem;
    height: 80px;
    margin-top: 80px;
    img{
      width: 50%;
    }
  }
  .content-fond{
    left: 0;
    top: 0;
    position: absolute;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
    width: 100%;
    height: 100%;
    .img{
      position: absolute;
      bottom: 0;
    }
  }
  @media (orientation: landscape){
    .content-front {
      flex-direction: row;
    }
  }
  .content-front{
    z-index: 1;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    .homme-place{
      flex-grow: 1;
      text-align: center;
      position: relative;
      img{
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%,-50%);
        width: 40%;
        transition: all 0.3s;
        transform: translate(-50%,-50%) scale(1.3);
      }
    }
    
  }
`

export default TimedSuspense;