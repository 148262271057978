import React, {lazy, Suspense} from "react";
import styled from "styled-components";
import {Routes, Route} from "react-router-dom";

import TimedSuspense from "../../components/organisms/layouts/TimedSuspense";


const Absence = lazy(()=>import("../Absences/Absence"))
const NotFound = lazy(()=>import("./NotFound"))
const AllAbsences = lazy(()=>import("../Absences/AllAbsences"))
const NewDemAbs = lazy(()=>import("../Absences/NewDemAbs"))




interface AbsenceRooterCtrlProps{
    className?:string;
}



const AbsenceRooterCtrl = ({className}:AbsenceRooterCtrlProps)=>{
    return (
        <div className={`Dashy ${className}`}>
            <Suspense fallback={<TimedSuspense/>}>
                <Routes>
                    <Route path="" element={<Absence/>}/>
                    <Route path="/all" element={<AllAbsences/>}/>
                    <Route path="/nouvelle" element={<NewDemAbs/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </Suspense>
        </div>
    )
}

const AbsenceRooter = styled(AbsenceRooterCtrl)`
    height: 100%;
`

export default AbsenceRooter;