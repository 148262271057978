import React, {lazy, Suspense, useState} from "react";
import styled from "styled-components";
import {Routes, Route} from "react-router-dom";

import TimedSuspense from "../../components/organisms/layouts/TimedSuspense";


const ProfilSignature = lazy(()=>import("./ProfilSignature"))
const NotFound = lazy(()=>import("./../MainStreams/NotFound"))
const Profil = lazy(()=>import("./Profil"))
const ProfilModification = lazy(()=>import("./ProfilModification"))
const ProfilChangePwd = lazy(()=>import("./ProfilChangePwd"))
const ProfilPinPointeuse = lazy(()=>import("./ProfilPinPointeuse"))






interface ProfilRooterCtrlProps{
    className?:string;
}



const ProfilRooterCtrl = ({className}:ProfilRooterCtrlProps)=>{
    const [test, setTest] = useState(false);
    return (
        <div className={`Dashy ${className}`}>
            {test === true ?
                <TimedSuspense/>:

                <Suspense fallback={<TimedSuspense/>}>
                    <Routes>
                        <Route path="" element={<Profil/>}/>
                        <Route path="/coordonnees" element={<ProfilModification/>}/>
                        <Route path="/modify_password" element={<ProfilChangePwd/>}/>
                        <Route path="/pin_pointeuse" element={<ProfilPinPointeuse/>}/>
                        <Route path="/my_sign" element={<ProfilSignature/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </Suspense>
            }
        </div>
    )
}

const ProfilRooter = styled(ProfilRooterCtrl)`
    height: 100%;
`

export default ProfilRooter;