import React from "react";
import styled from "styled-components";
import {Outlet} from "react-router-dom";
import {TabNavBar} from "./TabNavBar";

interface MainLayoutCtrlProps{
    className?:string;
}

const MainLayoutCtrl = ({className}:MainLayoutCtrlProps)=>{
    return (
        <div className={`MainLayout ${className}`}>
            <div className="mainContent">
                <Outlet/>
            </div>
            <TabNavBar/>
        </div>
    )
}

const MainLayout = styled(MainLayoutCtrl)`
     height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
    .mainContent{
      height: calc(100vh - 91px);
      background: white;
    }
`

export default MainLayout;