import {dataFetcher} from "../../services/dataGuetter/dataFetcher";

export async function getPlagesByParams(idUser:number, params:any|null):Promise<Plage[]>{
    let TabSearch:string[] = [];
    if(params) {
        for (const [key, value] of Object.entries(params)){
            TabSearch.push(`${key}=${value}`)
        }
    }
    const search = TabSearch.length>0 ? `&${TabSearch.join('&')}&User=${idUser}` : `&User=${idUser}`
    const res:any = await dataFetcher(`plages?pagination=false${search}`, {method:'get'})
    return res["hydra:member"];
}

export async function getPlagesByParams2(params:any|null):Promise<Plage[]>{
    let TabSearch:string[] = [];
    if(params) {
        for (const [key, value] of Object.entries(params)){
            TabSearch.push(`${key}=${value}`)
        }
    }
    const search = TabSearch.length>0 ? `&${TabSearch.join('&')}` : ``
    const res:any = await dataFetcher(`plages?pagination=false${search}`, {method:'get'})
    return res["hydra:member"];
}

export async function getPlageDay(plageAt:string):Promise<Plage[]>{
    const res:any = await dataFetcher(`plages?pagination=false&plageAt=${plageAt}`, {method:'get'})
    return res["hydra:member"];
}

export function getPlageBusy(From:string, To:string, idUser:number):Promise<PlageBusy[]>{
    return dataFetcher(`plages/get_plage_busy?idUser=${idUser}&From=${From}&To=${To}`, {method:'get'})
}