
export function getDateDay(){
    const Today = new Date();
    const myDay = TabJour.find(j=>j.num === Today.getDay());
    const dayStr = myDay ? myDay.name : 'nc';
    const myMth = TabMois.find(m=>m.numJS === Today.getMonth());
    const monthStr = myMth ? myMth.name : 'nc';
    return `${dayStr} ${Today.getDate()} ${monthStr} ${Today.getFullYear()}`

}
export function getDateMY(dat){
    const Today = dat;
    const myMth = TabMois.find(m=>m.numJS === Today.getMonth());
    const monthStr = myMth ? myMth.name : 'nc';
    return `${monthStr} ${Today.getFullYear()}`
}
export function getDateDY(dat){
    const Today = new Date();
    const monthT = Today.getMonth();
    const yearT = Today.getFullYear();
    const dateN = dat;
    const dayDateN = dateN.getDate();
    const monthN = dateN.getMonth();
    const yearTo = monthN >= monthT ? yearT : yearT+1
    const dateRel = new Date(yearTo, monthN, dayDateN)
    const myDay = TabJour.find(j=>j.num === dateRel.getDay());
    const dayStr = myDay ? myDay.name : 'nc';
    const myMth = TabMois.find(m=>m.numJS === dateRel.getMonth());
    const monthStr = myMth ? myMth.name : 'nc';
    return `${dayStr} ${dateRel.getDate()} ${monthStr} `
}

export const TabMois = [
    {num:1, numMois:'01', name:'Janvier', numJS:0},
    {num:2, numMois:'02', name:'Février', numJS:1},
    {num:3, numMois:'03', name:'Mars', numJS:2},
    {num:4, numMois:'04', name:'Avril', numJS:3},
    {num:5, numMois:'05', name:'Mai', numJS:4},
    {num:6, numMois:'06', name:'Juin', numJS:5},
    {num:7, numMois:'07', name:'Juillet', numJS:6},
    {num:8, numMois:'08', name:'Août', numJS:7},
    {num:9, numMois:'09', name:'Septembre', numJS:8},
    {num:10, numMois:'10', name:'Octobre', numJS:9},
    {num:11, numMois:'11', name:'Novembre', numJS:10},
    {num:12, numMois:'12', name:'Décembre', numJS:11},
];

export const TabJour = [
    {num:0, name:'Dimanche'},
    {num:1, name:'Lundi'},
    {num:2, name:'Mardi'},
    {num:3, name:'Mercredi'},
    {num:4, name:'Jeudi'},
    {num:5, name:'Vendredi'},
    {num:6, name:'Samedi'},
];

