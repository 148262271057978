import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";
import {useGetUser} from "../../features/user/user.hooks";
import {useGetMessagesNews} from "../../features/message/message.hooks";
import {TimedNotifNb} from "./TimedNotificationCentre/TimedNotifNb";

interface TimedOneNavMessagerieCtrlProps{
    className?:string;
    navTo:string;
    icon:React.ReactNode;
    libelle:string;
}



const TimedOneNavMessagerieCtrl = ({className, navTo, icon, libelle}:TimedOneNavMessagerieCtrlProps)=>{
    const location = useLocation();
    const [domain, setDomain] = useState('');
    const MessageQuery = useGetMessagesNews();
    const [newMessage, setNewMessage] = useState<Message[]>([]);
    useEffect(()=>{
        if(MessageQuery.data){
            setNewMessage(MessageQuery.data)
        }
    }, [MessageQuery.data])
    useEffect(()=>{
        const TabLoc = location.pathname.split('/');
        setDomain(TabLoc[2]);
    }, [location])
    return (
        <NavLink to={`/timed_application/${navTo}`} className={`TimedTabM ${className} ${domain === navTo ? 'isActive' : ''} `}>
            {newMessage.length>0 &&
                <TimedNotifNb nb={newMessage.length} top={`-5px`} right={"25px"}/>
            }
            <div className={`icon-place`}>{icon}</div>
            <div className={`lib-place`}>{libelle}</div>
        </NavLink>
    )
}

const TimedOneNavMessagerie = styled(TimedOneNavMessagerieCtrl)`
  flex-grow: 1;
  text-decoration: none;
  text-align: center;
  position: relative;
  .lib-place{
    font-size: 10px;
    text-align: center;
    color:${props => props.theme.Dark};
  }
  svg{
    .path_to_fill{
      stroke: ${props=>props.theme.Dark};
    }
    .path_to_fill2{
      fill: ${props=>props.theme.Dark};
    }
    .path_to_fill_primary{
      fill: ${props=>props.theme.Dark};
    }
    .path_to_fill_white{
      fill: ${props=>props.theme.Dark};;
    }
  }
    &.isActive{
      svg{
        .path_to_fill2{
          fill: ${props=>props.theme.Primary};
        }
        .path_to_fill_primary{
          fill: ${props=>props.theme.Primary};
        }
        .path_to_fill{
          fill:${props => props.theme.Primary};
          stroke:${props => props.theme.Primary};
        }
        .path_to_fill_white{
          fill: white;
        }
      }
      .lib-place{
        color:${props => props.theme.Primary};
      }
    }
`

export default TimedOneNavMessagerie;