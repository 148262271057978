import React, {useState} from "react";
import styled from "styled-components";


import {useNavigate} from "react-router-dom";
import {login} from "../../../features/user/user.services";
import TimedInputText from "../../../components/atoms/TimedInputText/TimedInputText";
import TimedLink from "../../../components/atoms/TimedLink/TimedLink";
import TimedFullError from "../../../components/atoms/TimedFullError/TimedFullError";
import TimedButton from "../../../components/atoms/TimedButton/TimedButton";



interface LoginFormCtrlProps{
    className?:string;
    location: any;
}
interface Err{
    username:string|null,
    password:string|null
}

const LoginFormCtrl = ({className, ...props}:LoginFormCtrlProps)=>{
    const navigate = useNavigate();
    const [datas, setDatas] = useState({username:"", password:""})
    const [errors, setErrors] = useState<Err>({username:null, password:null})
    const [errorsConnexion, setErrorsConnexion] = useState("")
    const [onSubmit, setOnSubmit] = useState(false);
    const handleSubmit = (e:any)=>{
        e.preventDefault();
        setErrorsConnexion("");
        setOnSubmit(true);
        let nbE = 0;
        if(datas.username===""){
            const myErrors = {...errors};
            setErrors({...myErrors, username: "obligatoire"})
            nbE++;
        }
        if(datas.password===""){
            const myErrors = {...errors};
            setErrors({...myErrors, password: "obligatoire"})
            nbE++;
        }
        if(nbE===0) {
            login(datas.username, datas.password)
                .then((result: any) => {
                    const params = localStorage.getItem("q");
                    let q = "";
                    if(params!=="" && params!==null){
                        q = params.replace(/['"]+/g, "");
                    }
                    localStorage.setItem("__timed_user_token__", JSON.stringify(result.token).replace(/['"]+/g, ""));
                    //Si l'utilisateur a été déconnecté (token expiré) on le redirige vers la page ou il était, sinon on le redirige vers l'accueil
                    props.location.state ? navigate(props.location.state.from + q) : navigate("/timed_application");
                })
                .catch((error:any) => {
                    setOnSubmit(false);
                    setErrorsConnexion("Association login/mot de passe incorrect");
                    if (error.message !== "401Error") {
                        throw error;
                    }
                });
        }
    }
    const handleSetField = (id:string, value:string)=>{
        setDatas({...datas, [id]:value});
    }
    return (
        <form onSubmit={(e)=>handleSubmit(e)} className={`LoginForm ${className}`}>
            <div className={`lineLog`}>
                <TimedInputText
                    id={"username"}
                    label={"Identifiant"}
                    value={datas.username}
                    setFieldValue={handleSetField}
                    isRequired={true}
                    isFull={true}
                    type={"text"}
                    isError={errors.username!==null}
                    helperText={errors.username!==null ? errors.username : ''}
                />
                <div className={`lineLog`}>
                    <TimedInputText
                        id={"password"}
                        label={"Mot de passe"}
                        value={datas.password}
                        setFieldValue={handleSetField}
                        isRequired={true}
                        isFull={true}
                        type={"password"}
                        isError={errors.password!==null}
                        helperText={errors.password!==null ? errors.password : ''}
                    />
                    <div className="mdp-forgot">
                        <TimedLink size={"xs"} text={"mot de passe oublié ?"} themeColor={"Primary"} onClick={()=>navigate('/')}/>
                    </div>
                </div>
                {errorsConnexion!=="" && <TimedFullError text={errorsConnexion} textAlign={"center"}/>}
                <div className={"submit-wrapper"}>
                    <TimedButton
                        themeColor={"Primary"}
                        isFull={true}
                        text={"Connexion"}
                        type={"submit"}
                        size={"l"}
                        isPending={onSubmit}
                    />
                </div>
            </div>

        </form>
    )
}

const LoginForm = styled(LoginFormCtrl)`
    display: flex;
  flex-direction: column;
  gap: 20px;
  .mdp-forgot{
    display: flex;
    flex-direction: row-reverse;
    padding: 0.2rem;
  }
`

export default LoginForm;