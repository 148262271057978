import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    addUserSignature,
    forgot_pwd,
    getInfosUser, getInfosUserMob,
    getUsersByCentre,
    getUsersByCentreBorn,
    modifyCoord,
    saveOptin, trashUserSignature
} from "./user.services";
import {toast} from "react-toastify";


export const useGetUser=():UseQueryResult<User, Error>=>{
    return useQuery<User, Error>(["user_connected"], getInfosUser, {
        staleTime:100000,
        refetchOnWindowFocus:false,
    })
}

export const useGetUserMob=():UseQueryResult<MeMob, Error>=>{
    return useQuery<MeMob, Error>(["user_connected_mob"], getInfosUserMob, {
        staleTime:100000,
        refetchOnWindowFocus:false,
    })
}

export const useSaveOptIn = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return saveOptin(id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["user_connected"])
            }
        }
    )
}

export const useGetAllUser=():UseQueryResult<User[], Error>=>{

    const queryClient = useQueryClient();
    const userConnected:MeMob|undefined = queryClient.getQueryData(["user_connected_mob"]);
    const CentreId = (userConnected === undefined) ? 0 : userConnected.idCentre;
    return useQuery<User[], Error>(["users_all", CentreId], ()=>getUsersByCentre(CentreId), {
        enabled:CentreId!==0
    })
}

export const useGetUserBornNow=():UseQueryResult<UserBirth[], Error>=>{
    return useQuery<UserBirth[], Error>(["users_births"], ()=>getUsersByCentreBorn(), {

    })
}

export const useModifyCoord = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (data:{id:number, datas:ModifyUserFD})=>{
            return modifyCoord(data.datas, data.id);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["user_connected"])
                queryClient.invalidateQueries(["users"])
            }
        }
    )
}

export const useForgotPwd = ()=>{
    return useMutation(
        (data:{username:string})=>{
            return forgot_pwd(data.username);
        }
    )
}

export const useTrashSignature = ()=>{
    return useMutation(
        (id:number)=>trashUserSignature(id)
    )
}
export const useAddSign = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (data:PostNewSignature) => {
            return addUserSignature(data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["users"]);
                queryClient.invalidateQueries(["user"]);
                queryClient.invalidateQueries(["user_connected"]);
                toast.success("signature modifiée !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    );
};