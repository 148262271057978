import {
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult
} from "react-query";
import {getByConv, getMessageNews, getMessagePages, newMessage, ReturnMessagePage} from "./message.services";
import {toast} from "react-toastify";



export const useGetByConversation = (id:number|undefined):UseQueryResult<Message[], Error>=>{
    return useQuery<Message[], Error>(["messages", id], ()=>getByConv(id), {
        enabled:id!==undefined
    })
}

export const useGetMessagesNews = ():UseQueryResult<Message[], Error>=>{
    return useQuery<Message[], Error>(["messages_new"], getMessageNews)
}

export const useGetMessagesInfinite = (idConversation:number|undefined):UseInfiniteQueryResult<ReturnMessagePage, Error>=>{
    const params = {Conversation:idConversation}
    return useInfiniteQuery<ReturnMessagePage, Error>(["messages_pagine", idConversation], ({pageParam=`messages?page=1`})=>getMessagePages(pageParam, params), {
        getNextPageParam: (lastPage)=>{return lastPage.nextPage},
        enabled:idConversation!==undefined
    })
}


export const useSendNewMessage = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:MessageFormDate)=>{
            return newMessage(datas);
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["conversations"]);
                queryClient.invalidateQueries(["participants"]);
                queryClient.invalidateQueries(["messages_pagine"]);
                console.log('yeah');
            },
            onError(e:any){
                toast.error("un problème est survenu")
            }
        }
    )
}

