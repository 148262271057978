import React from "react"
import styled from "styled-components";

interface TimedNotifNbCtrlProps{
    className?:string;
    nb:number;
    right:string;
    top:string;
}

const TimedNotifNbCtrl = ({className, nb, right, top}:TimedNotifNbCtrlProps)=>{
    return (
        <div className={`round-notif ${className}`} style={{right:right, top:top}}>{nb}</div>
    )
}

export const TimedNotifNb = styled(TimedNotifNbCtrl)`
    position: absolute;
  width: 18px;
  height: 18px;
  font-size: 10px;
  color:white;
  background: red;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`