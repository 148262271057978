import React from "react";
import styled from "styled-components";
import {useViewport} from "../../services/providers/ViewportProvider";
import LoginMobile from "./LoginMobile";
import LoginDesktop from "./LoginDesktop";

interface LoginCtrlProps{
    className?:string;
}

const LoginCtrl = ({className}:LoginCtrlProps)=>{
    const { width } = useViewport();
    const breakpoint = 620;
    return (
        <div className={`LoginPage ${className}`}>
            {width<breakpoint ? <LoginMobile/> : <LoginDesktop/>}
        </div>
    )
}

const Login = styled(LoginCtrl)``

export default Login;