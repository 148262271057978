import React, {useState} from "react";
import styled from "styled-components";
import {useForgotPwd} from "../../features/user/user.hooks";
import {useForm} from "react-hook-form";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";
import {useNavigate} from "react-router-dom";
import Security from "../../assets/images/security.png";

interface PwdRetrieveCtrlProps{
    className?:string;
}

const PwdRetrieveCtrl = ({className}:PwdRetrieveCtrlProps)=>{
    const mutation = useForgotPwd();
    const [ended, setEnded] = useState(false)
    const navigate = useNavigate();
    const defaultValue = {
        username:''
    }
    const methods = useForm({
        mode:'onChange',
        defaultValues:defaultValue
    })
    const onSubmit = (datas:any)=>{
        mutation.mutate(({username:datas.username}), {
            onSuccess:()=>{
                setEnded(true);
            }
        });
    }
    return (
        <div className={`pagePwd ${className}`}>
            <h3>Mot de passe oublié</h3>
            {ended ?
                <>
                    <div className={"wrap_img"}>
                        <img src={Security} alt={"mdp"}/>
                    </div>
                    <div className={`wrap-success`}>
                        <p className={"text_b"}>Votre demande a bien été prise en compte</p>
                        <p className={"text_b"}>Si l'identifiant indiqué est correct, vous allez recevoir dans quelques instants votre mot de passe provisoire</p>
                        <p className={"text_b"}>Pensez à vérifier vos spams</p>
                        <p className={"text_b"}>Si vous ne recevez aucun e-mail, rapprochez vous de votre responsable RH</p>

                        <div className={`wrap-btn-submit`} style={{marginTop:"30px"}}>
                            <TimedButton
                                type={"button"}
                                size={"xl"}
                                isFull={true}
                                themeColor={"Primary"}
                                text={"Retour à la connexion"}
                                onClick={()=>navigate('/')}
                            />
                        </div>
                    </div>
                </>:
                <>
                    <div className={"wrap_img"}>
                        <img src={Security} alt={"mdp"}/>
                    </div>
                    <p className={"text"} style={{fontSize:"18px", fontWeight:"bold"}}>Vous avez oublié votre mot de passe, Nous sommes là pour vous aider à le réinitialiser</p>
                    <ol>
                        <li>Indiquez votre identifiant ci-dessous</li>
                        <li>Cliquez sur "Ré-initialiser mon mot de passe" pour soumettre votre demande</li>
                        <li>Vous recevrez, d'ici quelques secondes, un e-mail contenant un nouveau mot de passe provisoire (n'oubliez pas de vérifier vos spams)</li>
                        <li>Utilisez ce nouveau mot de passe pour vous connecter à votre compte, et modifiez-le dans la section "profil"</li>
                    </ol>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <input placeholder={"votre identifiant Timed"} {...methods.register('username', {required:true})}/>
                        <div className={`wrap-btn-submit`}>
                            <TimedButton
                                type={"submit"}
                                size={"md"}
                                isFull={true}
                                themeColor={"Primary"}
                                isPending={mutation.isLoading}
                                text={"Ré-initialiser mon mot de passe"}
                            />
                        </div>
                    </form>
                </>
            }
        </div>
    )
}

const PwdRetrieve = styled(PwdRetrieveCtrl)`
    padding: 1rem;
  .text_b{
    color: ${props => props.theme.Primary};
    font-size: 20px;
    margin-bottom: 15px;
    text-align: center;
  }
  ol{
    margin: 30px auto;
    width: 85%;
    li{
      margin-bottom: 10px;
    }
  }
  .wrap_img{
    width: 70%;
    margin: 30px auto;
    img{
      width: 100%;
    }
  }
    .text{
      margin: 25px auto;
      font-size: 24px;
    }
    input{
      width: 100%;
      height: 38px;
      margin-bottom: 30px;
      text-align: center;
      border:solid ${props => props.theme.Neutre} 1px;
      border-radius: 4px;
      font-size: 24px;
    }
`

export default PwdRetrieve;