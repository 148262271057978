import React, {lazy, Suspense, useEffect, useState} from "react";
import {Routes, Route, Navigate, useLocation} from "react-router-dom";
import styled from "styled-components";
import MainLayout from "../components/organisms/layouts/MainLayout";
import NotFound from "./MainStreams/NotFound";
import TimedSuspense from "../components/organisms/layouts/TimedSuspense";
import AbsenceRooter from "./MainStreams/AbsenceRooter";
import MessagerieRooter from "./MainStreams/MessagerieRooter";
import ProfilRooter from "./Profil/ProfilRooter";
import {useGetUserMob} from "../features/user/user.hooks";
import {ModalOptIn} from "./MainStreams/ModalOptin";
import {queryClient} from "../App";
import {getAllLieux} from "../features/lieu/lieu.services";
import {getAllFonction} from "../features/Fonction/fonction.services";
import {FindFirstMondayLastSunday} from "../services/functions/TimesFunctions";
import {getPlagesByParams2} from "../features/plage/plage.services";
import {version_timed} from "../index";
import {useAddNavigation} from "../features/monitoringNavigation/monitoringNavigation.hooks";
import {useGetFrontVersion} from "../features/frontVersion/frontVersion.hooks";


interface ApplicationControllerCtrlProps{
    className?:string;
}

const Dashboard = lazy(()=>import("./MainStreams/Dashboard"));
const CreneauxRooter = lazy(()=>import("./MainStreams/CreneauxRooter"));
const Planning2 = lazy(()=>import("./Planning/Planning2"));

const ApplicationControllerCtrl = ({className}:ApplicationControllerCtrlProps)=>{
    const [versionBack, setVersionBack] = useState(version_timed);
    const VersionQuery = useGetFrontVersion();
    const UserQuery = useGetUserMob();
    const mutationNavigation = useAddNavigation();
    const location = useLocation();
    useEffect(() => {
        const Tab = location.pathname.split('/');
        const myRoadName = (Tab && Tab[2]) ? Tab[2] : 'dashboard'
        mutationNavigation.mutate({roadName:myRoadName, version:version_timed})
    }, [location.pathname]);
    useEffect(() => {
        if(VersionQuery.data){
            setVersionBack(VersionQuery.data.mobile);
        }
    }, [VersionQuery.data]);
    useEffect(() => {
        console.log('version_back>>'+versionBack);
    }, [versionBack]);
    useEffect(() => {
        const monthNow = new Date().getMonth();
        const year = new Date().getFullYear();
        //const {start, end} = getDateMonthByNumM(myM, year);
        const {FirstMonday:Monday, LastSunday:Sunday} = FindFirstMondayLastSunday(new Date());
        //const TabMonth = [Monday.getMonth()+1, Sunday.getMonth()+1]
        const Params = {"plageAt[after]":Monday.toISOString().slice(0,10), "plageAt[before]":Sunday.toISOString().slice(0,10)}
        const PlanningQuery = {queryKey:["plages_month", {months:[`${monthNow}_${year}`]}], queryFn:()=>getPlagesByParams2(Params)}
        queryClient.prefetchQuery(PlanningQuery);
        queryClient.prefetchQuery(["Lieux"], getAllLieux)
        queryClient.prefetchQuery(["fonctions"], getAllFonction)
    }, []);

    // const PlanningMonthQuery = useGetPlanningMonth(myM, new Date().getFullYear())
    return (
        <div className={`AppController ${className}`}>
            <Suspense fallback={<TimedSuspense/>}>
                <Routes>
                    <Route element={<MainLayout/>}>
                        <Route path="" element={<Navigate to="dashboard" />} />
                        <Route path="/dashboard/*" element={<Dashboard versionBack={versionBack}/>}/>
                        <Route path="/absences/*" element={<AbsenceRooter/>}/>
                        <Route path="/planning" element={<Planning2/>}/>
                        <Route path="/messagerie/*" element={<MessagerieRooter/>}/>
                        <Route path="/profil/*" element={<ProfilRooter/>}/>
                        <Route path="/creneaux/*" element={<CreneauxRooter/>}/>
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </Suspense>
            {UserQuery.data && !UserQuery.data.is_optin &&
                <ModalOptIn user={UserQuery.data}/>
            }
        </div>
    )
}

const ApplicationController = styled(ApplicationControllerCtrl)``

export default ApplicationController;