import React from "react";
import styled from "styled-components";

interface NotFoundCtrlProps{
    className?:string;
}

const NotFoundCtrl = ({className}:NotFoundCtrlProps)=>{
    return (
        <div className={`NotFound ${className}`}>
            <p>PAs trouvé</p>
        </div>
    )
}

const NotFound = styled(NotFoundCtrl)``


export default NotFound;