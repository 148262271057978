import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export async function getAllCentre():Promise<Centre[]>{
    const res:any = await dataFetcher(`centres`, {method:"get"})
    return res["hydra:member"];
}

export function getOneCentre(id:number):Promise<CentreComplet>{
    return dataFetcher(`centres/${id}`, {method:'get'})
}

export function setLittParams(id:number, litts:any):Promise<CentreComplet>{
    return dataFetcher(`centres/${id}`, {
        method:'put',
        body:{literalParameters:litts}
    })
}

export function setMobParameters(id:number, params:any):Promise<CentreComplet>{
    return dataFetcher(`centres/${id}`, {
        method:'put',
        body:{mobParameters:params}
    })
}